<template>
  <div class='news-content-info' ref='newsInfo'>
    <div v-if="noteInfo.contMomentResources && noteInfo.contMomentResources.length">
      <van-swipe class="image-swipe" 
        :autoplay="5000"
        indicator-color="#F7321C"
        :show-indicators="noteInfo.contMomentResources.length == 1 ? false : true"
        :initial-swipe="initialSwipe"
        @change="onSwipeChange" >
        <van-swipe-item v-for="item in noteInfo.contMomentResources" :key="item.sort">
          <div class="swipe-item-image" @click="showOpenSelDialog(noteInfo, momentDetail)">
            <img :src="item.uri" alt="">
          </div>
        </van-swipe-item>
        <div>
          <div class="custom-indicator">{{ swipeCurrent + 1 }}/{{noteInfo.contMomentResources.length}}</div>
        </div>
      </van-swipe>
    </div>
    <div class="news-content-info-container">
      <div class='is-note'>
        <div class='in-left'>
          <div class='avatar-images' @click='showOpenSelDialog(noteInfo, author)'>
            <img :src="noteInfo.author.avatar ? noteInfo.author.avatar : 'https://cdn.9kd.com/kdnet/a6593d8447984c8a9fc798ad5c327a1d.png'">
            <img v-if='noteInfo.author.certify && noteInfo.author.field' 
              class='vip-img'
              src='https://cdn.9kd.com/kdnet/%E8%AE%A4%E8%AF%81%403x.png' alt="认证图片">
          </div>
          <div class='avatar-info' @click='showOpenSelDialog(noteInfo, author)'>
            <p class='p1'>
              {{ noteInfo.author.nickname }}
              <img v-if='noteInfo.author.product' src='https://cdn.9kd.com/kdnet/kd-net-VIP.png' />
            </p>
            <p class='p2'>
              {{ noteInfo.postedAt | compareDate }} 
              {{ noteInfo.ipLocation}}
            </p>
          </div>
        </div>
        <div class='in-right'>
          <button tag='button' @click='showOpenSelDialog(noteInfo, author)'>+关注</button>
        </div>
      </div>
      <p class='news-title' v-html="noteInfo.title && noteInfo.title.replace(/\r\n/g, '<br/>').replace(/\n/g, '<br/>').replace(/\s/g, '&nbsp;')"></p>
      <div ref='textContent' :class="['news-content', {'show-height': shortdiv}]" v-html="noteInfo.body && noteInfo.body.replace(/\r\n/g, '<br/>').replace(/\n/g, '<br/>').replace(/\s/g, '&nbsp;')"></div>

      <div class='news-open' ref='newsOpen' v-if='shortdiv'>
        <div :class="{ 'content-mask': showMask }"></div>
        <div class='open-ico' @click='showOpenSelDialog(noteInfo, lookarticle)'><span class='font'>展开全文</span>
          <span class='iconfont ico'>&#xe65d;</span>
        </div>
        <div class='open-app' @click='showOpenSelDialog(noteInfo, momentDetail)'>
          <button>打开凯迪网APP,阅读体验更佳</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bus from '../utils/bus';
export default {
  props: {
    noteInfo: {
      type: Object,
      default: () => {
        author: {}
      }
    },
    initialSwipe: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      author: Bus.CONTINUTETYPE_AUTHOR,
      lookarticle: Bus.CONTINUTETYPE_LOOK_ARTICLE,
      momentDetail: Bus.MOMENT_DETAIL,
      swipeCurrent: 0,
      shortdiv: false,
      longdiv: true,
      showMask: false,
      updateFlag: false,
    }
  },
  updated() {
    if(!this.updateFlag && this.noteInfo.title){
      this.updateFlag = true;
      this.$nextTick(()=>{
        
      })
    }
  },
  methods: {
    showOpenSelDialog(data, type) {
      if(type == 'look-article') {
        this.longdiv = true
        this.shortdiv = false
      } else {
        this.$emit('show-open-select-dialog', data, type)
      }
    },
    onSwipeChange(index) {
      this.swipeCurrent = index;
    },
    setImageMore() {
      var index = 0
      var imgArr = this.$refs.textContent.getElementsByTagName('img')
      var len = 0;
      if (imgArr.length > 0) {
        var self = this;
        for (let item of imgArr) {
          if (item.src) {
            len++
          }
          item.onload = function() {
            index++
            if (index >= len) {
              self.continueFnc()
            }
          }
          item.onerror = function() {
            index++
            if (index >= len) {
              self.continueFnc()
            }
          }
        }
      } else {
        this.continueFnc()
      }
    },
    continueFnc() {
      let height = this.$refs.textContent.offsetHeight
      if (height > 250) {
        this.showMask = true
        this.longdiv = false
        this.shortdiv = true
      } else {
        this.longdiv = false
        this.shortdiv = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .news-content-info {
    box-sizing: border-box;
    
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: left;
    .news-content-info-container{
      padding: 0 13px 13px 13px;
    }
    .news-title {
      font-size: 20px;
      font-weight: bold;
      color: #242831;
      line-height: 30px;
      letter-spacing: 1px;
    }

    .is-note {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      height: 28px;
      font-weight: 400;
      margin-bottom: 8px;

      .in-left {
        display: flex;
        align-items: center;
        font-size: 13px;
        color: #9099a6;
        letter-spacing: 1px;

        .avatar-images {
          width: 28px;
          height: 28px;
          border-radius: 50%;
          margin-right: 10px;
          position: relative;

          img {
            width: 28px;
            height: 28px;
            border-radius: 50%;
          }

          .vip-img {
            width: 14px;
            height: 14px;
            position: absolute;
            bottom: 0;
            right: -3px;
          }
        }

        .avatar-info {
          color: #3a3e45;
          font-size: 13px;

          .p1 {
            display: flex;
            align-items: center;

            img {
              margin-left: 5px;
              width: 20px;
              height: auto;
            }
          }

          .p2 {
            font-size: 10px;
            color: #9099a6;
          }
        }
      }

      .in-right {
        display: flex;
        align-items: center;

        button {
          width: 54px;
          height: 26px;
          background: #F7321C;
          border-radius: 13px;
          outline: none;
          border: none;
          font-size: 11px;
          color: #ffffff;
          letter-spacing: 1px;
        }
      }
    }

    .news-content {
      font-size: 16px;
      color: #414449;
      letter-spacing: 1px;
      position: relative;
      box-sizing: border-box;
      padding-top: 8px;

      p {
        margin-bottom: 15px;
        line-height: 25px;
      }

      img {
        width: 100% !important;
        height: auto !important;
      }

    }

    .show-height {
      max-height: 250px;
      overflow: hidden;
    }

    .news-open {
      text-align: center;
      position: relative;

      .content-mask {
        width: 100%;
        height: 34px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0) 4%, #ffffff);
        position: absolute;
        left: 0;
        top: -33px;
      }

      .open-ico {
        z-index: 999;
        text-align: center;
        height: 16px;
        line-height: 5px;
        color: #9099a6;

        .font {
          font-size: 14px;
        }

        animation: shake 1000ms infinite;
      }

      .open-app {
        margin-top: 20px;
        font-size: 13px;
        color: #F7321C;
        line-height: 40px;
        letter-spacing: 1px;

        button {
          width: 212px;
          height: 40px;
          border: 1px solid #F7321C;
          border-radius: 20px;
          background: #ffffff;
        }
      }
    }
  }
  .image-swipe{
    .van-swipe-item {
      height: 400px;
      background-color: #fff;
      .swipe-item-image{
        height: 93%;
      }
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .van-swipe__indicator{
      background: #EEEEEE;
    }
  }
</style>
<style>
@keyframes shake {
  from {
    transform: translateY(0px);
  }
  20% {
    transform: translateY(2px);
  }
  40% {
    transform: translateY(4px);
  }
  60% {
    transform: translateY(6px);
  }
  80% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(10px);
  }
  80% {
    transform: translateY(8px);
  }
  60% {
    transform: translateY(6px);
  }
  40% {
    transform: translateY(4px);
  }
  to {
    transform: translateY(0px);
  }
}
</style>