<template>
  <div :class="['news-detail', {'no-margin': noteInfo.status != 2}]">
    <!-- 头部 -->
    <news-head
      @on-click-left="onClickLeft"
      @on-click-right="onClickRight">
    </news-head>
    <examine v-if="noteInfo.status != 2"></examine>
    <div v-else>
      <!--   内容区   -->
      <moment-content ref="momentContent"
      :noteInfo="noteInfo"
      :initialSwipe="initialSwipe"
      @show-open-select-dialog="showOpenSelDialog">
      </moment-content>

      <!-- 关联商品 -->
      <goods-card v-if="goodsData" :goodsData="goodsData" @to-goods-detail="toGoodsDetail"></goods-card>

      <!-- 标签 -->
      <div class="labels-content">
        <div v-for="item in noteInfo.contentLabels" :key="item.labelOrder" class="labels-item" @click='$router.push(`/lableaggregatepage/${item.labelName}`)'>
          # {{item.labelName}}
        </div>
      </div>

      <!--   评论区   -->
      <comment-list
        :commentsData="comments"
        :noteInfo="noteInfo"
        @show-open-select-dialog="showOpenSelDialog">
      </comment-list>
    </div>

    <!-- 分享 -->
    <van-share-sheet @select='selectTag' v-model='showShare' :options='options' />

    <!-- 发现更多热门内容 -->
    <hot-new-dialog
      :show-more-hot-dialog="showMoreHotDialog"
      :hotNewData="hotNews"
      @close-more-hot-dialog="closeMoreHotDialog"
      @queryone-and-show-open="queryoneAndShowOpen"
      @on-click-more="onClickMore">
    </hot-new-dialog>

    <!-- 底部按钮，唤起APP/下载APP -->
    <open-app-button @openApp="onClickRight()"></open-app-button>

  </div>
</template>

<script>
import Bus from '../../utils/bus'
import GoodsCard from '../../components/GoodsCard'
import NewsHead from '../../components/NewsHead.vue'
import MomentContent from '../../components/MomentContent.vue'
import OpenAppButton from '../../components/OpenAppButton.vue'
import Examine from '../../components/Examine.vue'

export default {
  props: ['id'],
  name: 'noteDetail',
  components: {
    GoodsCard,
    NewsHead,
    HotNewDialog: () => import('../../components/HotNewDialog.vue'),
    CommentList: () => import('../../components/CommentList.vue'),
    MomentContent,
    OpenAppButton,
    Examine
  },
  data() {
    return {
      startflag: false,
      noteInfo: {
        author:{
        },
        status: 95
      },
      // 评论
      comments: [],
      // 分享
      showShare: false,
      options: [
        [
          { name: '微信', icon: 'wechat' },
          { name: 'QQ', icon: 'qq' }
        ]
      ],
      showMoreHotDialog: false,
      moreHotTimer: null,
      hotNews: [],
      defaultCoverImg: 'https://cdn.9kd.com/kdnet/3686ec305ae84fcc9e33bd263bcab032.jpg',
      composeSuccessed: false,
      momentDetail: Bus.MOMENT_DETAIL,
      goodsData: null, // 关联商品数据,
      initialSwipe: 0,
      isCheck: true
    }
  },
  watch: {
    '$route'(val, oldval) {
      this.queryCommunityCommentLists();
    }
  },
  created() {
    Bus.$emit(Bus.SHOW_DOWN_FLOATING, false);
    Bus.$emit(Bus.SHOW_LOOP_FLOATING, true);
    // 标签页进来会带图片下标，需要定位到图片
    if (this.$route.query.index != undefined) {
      this.initialSwipe = parseInt(this.$route.query.index);
    }
    // 获取动态详情
    this.getDynamicDetail();
  },
  mounted() {
    Bus.$on(Bus.UPDATE_NEW_INFO, () => {
      this.getDynamicDetail();
    })
  },
  beforeDestroy() {
    Bus.$off(Bus.UPDATE_NEW_INFO);
    this.clearMoreHotTimer();
    this.$ttsRecorder.stop();
    this.$ttsRecorder.resetAudio();
  },
  methods: {
    /**
     * 获取详情
     */
    getDynamicDetail(){
      this.$api.getDynamicDetail({code: this.id}).then( result => {
        let res = result.data;
        if(res.code == 200) {
          this.noteInfo = res.data;
          let articleId = this.noteInfo.articleId;
          let id = this.noteInfo.id;
          this.noteInfo.id = articleId;
          this.noteInfo.dynamicId = id;
          this.noteInfo.continuteType = this.momentDetail;
          this.$nextTick(()=>{
            this.$refs.momentContent.setImageMore()
          })
          // 获取评论
          this.queryCommunityCommentLists();
          // 为西南配置
          this.queryWxConfigs();
          // 获取商品信息
          this.getTaskInfo();
        }
      }).catch(err=>{
        console.log(err);
      })
    },
    // 对ISO不支持webp格式图片的临时处理
    formatImgForWebp(mydata) {
      return mydata.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, function (match,capture) {
        return match.replace(/src=['"]([^'"]+)[^>]*>/gi, function(match1,r) {
          if (r.includes('.gif')) return match1
          return match1 = `src="${r}?x-oss-process=image/format,jpg">`
        })
      });
    },
    /**
     * 请求后台接口获取微信配置信息
     */
    async queryWxConfigs(voice) {
      let { data: result } = await this.$api.queryone(this.$route.params.id);
      let sharedata = {};
      if (result.code == 200) {
        sharedata = result.data;
        sharedata.content = sharedata.content.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, ''); // 去掉所有html标记的函数
      }
      // 全局混入函数
      this.setWeChatShare({
        url: window.location.href,
        title: sharedata.title || '',
        description: sharedata.content ? sharedata.content : '',
        friendImg: sharedata.firstPicture ? sharedata.firstPicture : this.$baseInfo.LOGO_IMG,
        momentsImg: sharedata.firstPicture ? sharedata.firstPicture : this.$baseInfo.MOMENTS_LOGO_IMG
      });
    },
    // 选择分享
    selectTag(tag) {
      if (tag.name === 'QQ') {
        this.qzone();
      } else {
        this.$toast({
          type: 'html',
          message: '<p>使用浏览器的分享功能，把这</p><p style="text-align:left;">篇文章分享出去</p>'
        })
        this.showTheMoreHotDialog();
      }
      this.showShare = false;
    },
    // qq分享
    qzone() {
      console.log(this.noteInfo);
      let url = location.href;
      var shareqqzonestring =
        'http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=' + url + '&title=' + this.noteInfo.title + '&desc=' + '【分享来之凯迪网@凯迪网】' + '&summary=&site=&pics='
      window.open(shareqqzonestring);
      window.open(shareqqzonestring, 'newwindow', 'height=100, width=400, top=0,left=0, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no');
      this.showTheMoreHotDialog();
    },
    //查询评论
    queryCommunityCommentLists() {
      let params = {
        articleId: this.noteInfo.id,
        limit:  3,
        onlyAuthor: 0,
        page: 1
      }
      this.$api.queryCommunityCommentList(params).then(res => {
        let data = res.data;
        if (data.code !== 200) return;
        this.comments = data.data.records;
      })
    },
    // 查询文章详情
    queryoneAndShowOpen(noteInfo, continuteType, index) {
      this.$api.queryone(noteInfo.id).then(res => {
        let data = res.data;
        if (data.code !== 200) {
          this.$notify({ type: 'primary', message: '文章查询失败' });
          return
        }
        if (data.data.id === noteInfo.id) {
          if (index < 3 || index === undefined) {
            this.showOpenSelDialog(data.data, continuteType);
          } else {
            this.$ttsRecorder.stop();
            this.$ttsRecorder.resetAudio();
            this.startflag = false;
            this.$router.push(`/note/${data.data.id}`);
          }
        }
      })
    },
    // 打开App选择器
    showOpenSelDialog(noteInfo, continuteType) {
      Bus.$emit(Bus.SHOW_OPEN_DIALOG, { show: true, noteInfo: noteInfo, continuteType: continuteType });
    },
    // 左边logo点击事件
    onClickLeft() {
      Bus.$emit(Bus.SHOW_LOOP_FLOATING, false);
      this.$router.push({
        path: '/topic'
      })
    },
    // 右侧分享点击事件
    onClickRight() {
      this.showOpenSelDialog(this.noteInfo, this.momentDetail);
    },
    //分享成功后，显示热榜弹窗
    showTheMoreHotDialog() {
      this.clearMoreHotTimer();
      this.moreHotTimer = setTimeout(() => {
        this.queryHotNewsData();
      }, 2000)
    },
    //获取24h热闻前3条
    queryHotNewsData() {
      this.$api.queryHotNews(3).then(res => {
        let data = res.data;
        if (!data || data.code !== 200) {
          return
        }
        this.hotNews = data.data;
        Bus.$emit(Bus.SHOW_LOOP_FLOATING, false);
        this.showMoreHotDialog = true;
      })
    },
    // 关闭更多热门弹框
    closeMoreHotDialog() {
      this.showMoreHotDialog = false;
      Bus.$emit(Bus.SHOW_LOOP_FLOATING, true);
    },
    /**进入热榜页面*/
    onClickMore() {
      this.showMoreHotDialog = false;
      this.$router.push('/hotlist');
    },
    // 清除热门弹框定时器
    clearMoreHotTimer() {
      if (this.moreHotTimer) {
        clearTimeout(this.moreHotTimer);
      }
    },
    // 获取关联的云商会产品
    async getTaskInfo() {
      let { data: res } = await this.$api.getGoodsInfo({articleId: this.noteInfo.id});
      if(res.code == 200 && res.data) {
        this.goodsData = res.data;
        this.goodsData.price = this.goodsData.price.toString();
      }
    },
    // 去云商会产品页面
    toGoodsDetail(url) {
      this.$api.postGoodsClick({
        articleId: this.noteInfo.id,
        goodsId: this.goodsData.goodsId,
        goodsName: this.goodsData.goodsName
      }).then((res)=>{})
      window.open(url);
    }
  }
}
</script>

<style lang='less'>
.news-detail {
  word-wrap: break-word;
  overflow: hidden;
  margin-bottom: 75px;
  &.no-margin{
    margin-bottom: 0;
  }
  .labels-content{
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    line-height: 15px;
    .labels-item{
      padding: 4px 10px 3px 10px;
      margin: 0 8px 8px 0;
      border-radius: 20px;
      background: #FAF3F3;
      color: #F7321C;
      font-weight: bold;
      font-size: 11px;
    }
  }
}
</style>
